import { MutableRefObject, useEffect, useRef } from 'react'
import style from './index.module.scss'
type PropsType = {
  // preview?: string[];
  wrapperClassName?: string
  contentClassName?: string
  fillClassName?: string
  children: any
  roomRef: MutableRefObject<HTMLDivElement>
}

function debounce(func, time = 100) {
  let timer
  return function (event) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(func, time, event)
  }
}

const PageContent = (props: PropsType) => {
  const { roomRef } = props
  // const roomRef = useRef<HTMLDivElement>()
  const wrapperRef = useRef<HTMLDivElement>()

  const setSize = () => {
    let { innerWidth: viewWidth, innerHeight: viewHeight } = window

    wrapperRef.current?.setAttribute(
      'style',
      `height:${viewHeight}px;width:${viewWidth}px;display:flex;justify-content:center; align-items:center;`
    )
    const proportion = 1920 / 1080

    while (proportion > viewWidth / viewHeight) {
      if (proportion > viewWidth / viewHeight) {
        viewHeight--
      } else {
        viewWidth--
      }
    }
    viewWidth = viewHeight * proportion
    roomRef.current?.setAttribute('style', `height:${viewHeight}px;width:${viewWidth}px;font-size:${viewWidth / 10}px;`)
  }

  const onResize = debounce(setSize, 200)

  useEffect(() => {
    setSize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])
  return (
    <div className={props.wrapperClassName} ref={wrapperRef}>
      <div className={props.fillClassName}></div>
      <div className={props.contentClassName} ref={props.roomRef}>
        {props.children}
      </div>
    </div>
  )
}
export default PageContent
