export function desensitization(str: string | undefined | null, beginLen = 4, endLen = -4, fillingLen = 4) {
    if (str === 'null') return 'null'
    if (!str) return ''
    let tempStr = ''
    const len = str.length
    const firstStr = str.substring(0, beginLen)
    const lastStr = str.substring(len + endLen, len)
    const middleStr = str.substring(beginLen, beginLen + fillingLen).replace(/[\s\S]/gi, '.')
    tempStr = firstStr + middleStr + lastStr
    return tempStr
  }