import 'react-toastify/dist/ReactToastify.css'
import '../styles/globals.css'
import '../styles/SelectWalletModal.less'
import '../styles/intro.less'
import '../styles/EntryDoor.less'
import '../styles/OpenDoor.less'
import { ChakraProvider, useMediaQuery } from '@chakra-ui/react'
import theme from '../theme'
import EnterPassword from '../components/EnterPassword'
import Tip from '../components/tip'
import Closed from '../components/closed'
import { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { Web3ReactProvider } from '@web3-react/core'
import { ethers } from 'ethers'

const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = 8000 // frequency provider is polling
  return library
}

function AppWrapper({ Component, pageProps, passwordCorrect, setPasswordCorrect }) {
  const [loading, setLoading] = useState(true)
  const [isLess1280] = useMediaQuery('(max-width: 1280px)')

  useEffect(() => {
    const password = localStorage.getItem('foundPassword')

    const checkPassword = async (password) => {
      const response = await fetch('/api/check-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password
        })
      })

      const content = await response.json()

      if (content.verification) {
        setPasswordCorrect(true)
        setLoading(false)
      } else {
        setPasswordCorrect(false)
        setLoading(false)
      }
    }

    if (password) {
      // setPasswordCorrect(true)
      // setLoading(false)
      checkPassword(password)
    } else {
      setLoading(false)
    }
  }, [])

  if (loading) return <div>Loading</div>

  return isLess1280 ? (
    <Tip></Tip>
  ) : process.env.NEXT_PUBLIC_PASSWORD_PROTECTED == 'true' && !passwordCorrect ? (
    <EnterPassword handleClose={setPasswordCorrect} />
  ) : (
    <Closed />
  )
}

function MyApp({ Component, pageProps }) {
  const [passwordCorrect, setPasswordCorrect] = useState(false)

  return (
    <ChakraProvider theme={theme}>
      <ToastContainer position="top-right" hideProgressBar={true} autoClose={2500} />
      <Web3ReactProvider getLibrary={getLibrary}>
        <AppWrapper
            Component={Component}
            pageProps={pageProps}
            passwordCorrect={passwordCorrect}
            setPasswordCorrect={setPasswordCorrect}
          /> 
      </Web3ReactProvider>
    </ChakraProvider>
  )
}

export default MyApp
