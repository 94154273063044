import { Box, Image, Flex, Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react'
import { useState } from 'react'
import SelectWalletModal from '../Modal/SelectWalletModal'
import { desensitization } from '../../utils'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useRouter } from 'next/router'

export default function Header(props) {
  const [open, setOpen] = useState(false)
  const account = localStorage.getItem('WALLET_ADDRESS')
  const router = useRouter()
  const logo = props.logo || (
    <>
      <Image
        src="./img/home/MerryModz-Logo.png"
        alt=""
        sx={{
          width: '15%'
        }}
        _hover={{}}
      />
    </>
  )

  return (
    <>
      <SelectWalletModal
        isOpen={open}
        closeModal={() => {
          setOpen(false)
        }}
        connectedWallet={() => {
          props?.connectedWallet()
        }}
      ></SelectWalletModal>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 99,
          top: 1,
          left: 1,
          width: '100%',
          flexGrow: 1,
          marginTop: '0.6%'
        }}
      >
        {logo}
        <Flex alignItems="center" position="absolute" right="5%" top="0.5%" justifyContent="space-between">
          <Image
            sx={{ width: '40px', cursor: 'pointer', marginRight: '22px' }}
            onClick={() => window.open('https://merrymodz.io/bark')}
            src="./img/home/bark-decorator.svg"
            alt=""
          />
          <Image
            sx={{ width: '40px', cursor: 'pointer', marginRight: '22px' }}
            onClick={() => window.open('https://twitter.com/impact_theory')}
            src="./img/home/twitter.svg"
            alt=""
          ></Image>
          <Image
            sx={{ width: '40px', cursor: 'pointer', marginRight: '22px' }}
            onClick={() => window.open('https://discord.com/invite/impacttheory')}
            src="./img/home/discord.svg"
            alt=""
          ></Image>

          {!props.hideWalletConnect && (
            <Box
              onClick={() => {
                if (!account) {
                  setOpen(true)
                }
              }}
              sx={{
                // margin: '0 4%',
                padding: account ? '0' : '7px 32px',
                boxShadow: '0px 10px 20px #00000033',
                border: '1px solid rgba(255, 255, 255, 0.35)',
                borderRadius: '5px',
                fontFamily: 'typrighter',
                cursor: 'pointer',
                color: 'rgba(255, 255, 255, 0.627)',
                background: '#433D6996 0% 0% no-repeat padding-box',
                backdropFilter: 'blur(15px)',
                fontSize: '14px'
              }}
              _hover={{
                color: '#FFF',
                boxShadow: '0px 10px 20px #00000033',
                border: '1px solid #FFFFFFCC',
                background: '#5e598096 0% 0% no-repeat padding-box'
              }}
            >
              {account ? (
                <>
                  <Menu>
                    <MenuButton
                      padding="0 32px"
                      bg="transparent"
                      outline="none"
                      _hover={{ bg: 'transparent' }}
                      _expanded={{ bg: 'transparent' }}
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                    >
                      {desensitization(account)}
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        color="red"
                        outline="none"
                        _hover={{ bg: 'transparent' }}
                        _expanded={{ bg: 'transparent' }}
                        onClick={() => {
                          localStorage.removeItem('WALLET_ADDRESS')
                          router.push({
                            pathname: '/'
                          })
                        }}
                      >
                        Disconnect
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>
              ) : (
                'Connect Wallet'
              )}
            </Box>
          )}
        </Flex>
      </Box>
    </>
  )
}
