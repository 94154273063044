import { Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'
export default function TermsAndConditions() {
  const router = useRouter()
  return (
    <Text
      zIndex="100"
      position="absolute"
      fontFamily="Courier"
      fontSize="13px"
      lineHeight="32px"
      left="31px"
      bottom="12px"
    >
      ©{new Date().getFullYear()} Merry Modz LLC. All rights reserved.
      <Text
        display="inline"
        cursor="pointer"
        onClick={() =>
          router.push({
            pathname: '/terms-and-conditions'
          })
        }
      >[T+C]</Text>
    </Text>
  )
}
