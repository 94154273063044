import { Box, Image, Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useRef, useState } from 'react'
import PageContent from '../../components/page-content'
import Header from '../header'
import TermsAndConditions from '../terms-and-conditions'
import style from './index.module.scss'

export default function Tip() {
  const router = useRouter()
  const [next, setNext] = useState(false)

  const roomRef = useRef<HTMLDivElement>()

  return (<PageContent 
      roomRef={roomRef}
      wrapperClassName={style.wrapper}
      fillClassName={`${style.fill} ${next && style.fill_door}`}
      contentClassName={style.content}
    >
      <div className={`${style.bg1} ${next ? style.bg1_to_top : ''}`}>
        <Box
          sx={{
            background: 'url(./img/home/bg.png) center/cover no-repeat',
            height: '100%',
            position: 'relative'
          }}
        >
          <Header hideWalletConnect={true}></Header>
          <Image src="./img/home/christmas_header2.png" alt="" width="65%"></Image>
          {/* <Image
          src="./img/home/Krampus.png"
          alt=""
          sx={{
            width: '30.26%',
            position: 'absolute',
            top: '12.396%',
            left: '33.02%'
          }}
        ></Image> */}
          <Text textAlign="center" fontFamily="enigma" position="absolute" top="30%" fontSize="1.2rem" width="80vw" left="10vw">
            Sorry, the Krampus Experience is now closed.
          </Text>
          <Image
            src="./img/home/snow-header_background.png"
            alt=""
            sx={{
              width: '100%',
              position: 'absolute',
              bottom: '0',
              left: '0'
            }}
          ></Image>
          {/* <Image src="./img/home/snow_hole.png" className={`${style.snow_hole} ${next && style.snow_hole_show}`} alt=""></Image> */}
        </Box>
      </div>
      {/* <Box
        className={`${style.bot_button} ${next ? style.bot_button_to_bottom : ''}`}
        onClick={() => {
          setNext(true)
        }}
      ></Box> */}
      {/* <Image src="./img/home/snow_hole.png" className={`${style.snow_hole} ${next && style.snow_hole_show}`} alt=""></Image> */}
      {/* <div className={`${style.bg2_door} ${next ? style.bg2_door_to_top : ''}`}></div> */}
      <TermsAndConditions></TermsAndConditions>
    </PageContent>
  )
}
