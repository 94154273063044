import { Modal, Text } from '@chakra-ui/react'
import { Image } from '@chakra-ui/react'
import { useWeb3React } from '@web3-react/core'
// import { connectors } from '../../utils/connectors'
import { useRouter } from 'next/router'
import { ERC721TokenType, Link } from '@imtbl/imx-sdk'
import { walletConnectProvider } from '../../utils/provider'
import Web3 from 'web3'

const provider = walletConnectProvider

const link = new Link(process.env.NEXT_PUBLIC_IMX_LINK || 'https://link.sandbox.x.immutable.com')

export default function SelectWalletModal(props) {
  const { isOpen, closeModal } = props
  const { activate } = useWeb3React()
  const router = useRouter()

  const setProvider = (type) => {
    window.localStorage.setItem('provider', type)
  }

  const metamaskFunc = async () => {
    let result = await link.setup({
      providerPreference: 'metamask'
    })
    if (result && result.address) {
      localStorage.setItem('WALLET_ADDRESS', result.address)
      closeModal()
      if (props?.connectedWallet) {
        props.connectedWallet()
      } else {
        router.push({
          pathname: '/select-nft'
        })
      }
    }
  }

  const walletConnectFunc = async () => {
    // let result = await link.setup({
    //   providerPreference: 'wallet_connect'
    // })
    await provider.enable()
    const web3 = new Web3(provider)
    const [address] = await web3.eth.getAccounts()
    console.log(address)
    if (address) {
      localStorage.setItem('WALLET_ADDRESS', address)
      closeModal()
      if (props?.connectedWallet) {
        props.connectedWallet()
      } else {
        router.push({
          pathname: '/select-nft'
        })
      }
    }
  }

  return (
    <div>
      <Modal isOpen={isOpen} onClose={closeModal} isCentered>
        <div
          className="walletModal"
          onClick={(e) => {
            closeModal()
          }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
            }}
          >
            <div className="title">To begin, please connect your wallet</div>
            <div className="wallet">
              <div className="metamask common" onClick={metamaskFunc}>
                <Image src="/img/metamask.svg" alt="Metamask Logo" width={121} height={112} />
                <Text marginTop={10}>Metamask</Text>
              </div>
              <div className="walletConnect common" onClick={walletConnectFunc}>
                <Image src="/img/walletconnect.svg" alt="Wallet Connect Logo" width={121} height={121} />
                <Text marginTop={10}>Wallet Connect</Text>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
